.container {
    grid-template-columns: 1fr;
    .container-item {
        display: flex;
        h4 {
            font-size: 1.3rem;
            line-height: 1.3rem;
            text-transform: uppercase;
            position: relative;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            margin-bottom: 52px;
            padding-bottom: 10px;
            width: 100%;
            text-align: left;
        }
    }
}

.card-core {
    display: inline-block;
    list-style-type: none;
    .card {
        min-width: 200px;
        max-width: 200px;
    }
    li {
        cursor: pointer;
        height: 340px;
        display: inline-block;
        transition: all 0.6s ease-in-out 0.1s;
        padding-right: 5%;
        padding-left: 5%;
        margin-right: 10px;
        p {
        color: #7b7c7e;
        font-size: 1.3rem;
        line-height: 2rem;
        margin: 0 0 52px;
        }
        &:hover {
            background-color: #353535;
            color: white;
        }
    }
}