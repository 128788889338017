.container {
    grid-template-columns: 1fr;
  .container-item {
    display: flex;
    flex-direction: column;
    @media (min-width: 992px) {
      margin-left: 8.333%;
      width: 83.333%;
    }
    & > div:first-child {
      flex: 1;
    }
    & > div:nth-child(2) {
      flex: 2;
    }
  }
}



.logo_pic {
background-size: 100% auto;
background-repeat: no-repeat;
clear: both;
display: block;
position: relative;
margin-left: 60px;

  .logo_pic_image:hover {
    opacity: 0;
  }

}

.logo_pic_image, .logo_pic_image_  {
position: absolute;
left: 25%;
width: 60%;

  -webkit-transition: all 2s ease;
     -moz-transition: all 2s ease;
       -o-transition: all 2s ease;
      -ms-transition: all 2s ease;
          transition: all 2s ease;
}